import { FRONT_URL } from '../const/const';
import popup from './popup';
import redirect from './redirect';
import sendToMobile from './sendToMobile';

interface ConfInterface {
  method: 'redirect' | 'popup';
}

interface ConfMobileInterface {
  method: 'sms' | 'email' | 'whatsapp';
  email: string;
}

class WebVerification {
  private apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public getUrlToOnlyCaptureImages = ({
    maxHeight,
    minHeight,
    typeId,
  }: {
    maxHeight?: string;
    minHeight?: string;
    typeId?: string;
  }) => {
    const json = {
      apiKey: this.apiKey,
      identifier: '',
      popup: false,
      mobile: false,
      onlyCapture: true,
      typeId,
      minHeight,
      maxHeight,
    };
    const jsonToBase64 = btoa(JSON.stringify(json));
    return `${FRONT_URL}?state=${jsonToBase64}`;
  };

  public verifyIdentity = (
    identifier: string,
    conf: ConfInterface = {
      method: 'redirect',
    }
  ) => {
    switch (conf?.method) {
      case 'redirect':
        redirect({ apiKey: this.apiKey, identifier });
        break;
      case 'popup':
        popup({ apiKey: this.apiKey, identifier });
        break;
    }
  };

  public verifyIdentityMobile = async (
    identifier: string,
    conf: ConfMobileInterface = {
      method: 'email',
      email: '',
    }
  ) => {
    const response = await sendToMobile({
      apiKey: this.apiKey,
      identifier,
      method: conf?.method,
      email: conf?.email,
    });
    return response;
  };
}

export default WebVerification;
